import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './ascension.scss';
import { Button } from 'react-bootstrap';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const EsGuidesTownBuildingsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ascension-guide'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Town buildings catalogue</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_town.png"
            alt="Town buildings catalogue"
          />
        </div>
        <div className="page-details">
          <h1>Town buildings catalogue</h1>
          <h2>List of buildings available in Eversoul.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Town buildings catalogue" />
        <StaticImage
          src="../../../images/eversoul/generic/guide_towntracker2.jpg"
          alt="Guide"
        />
        <p>
          The Town system allows you to collect nearly a hundred different
          building and this catalogue will make it easier for you to track your
          collection progress and also know what different types of buildings
          are available in the game.
        </p>
        <p>You can check out the sheet here:</p>
        <OutboundLink
          href="https://docs.google.com/spreadsheets/d/1I8bDVc5CmXFUOff8iQQtzq5Gh6OJ57Y4KhDxiQPHXdI/edit#gid=1553900952"
          target="_blank"
        >
          <Button variant="primary">Building catalogue</Button>
        </OutboundLink>
        <br />
        <br />
        <p>
          Huge thank you to <strong>Erayto#7867</strong> from the Prydwen
          Discord for creating this sheet!
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesTownBuildingsPage;

export const Head: React.FC = () => (
  <Seo
    title="Town buildings catalogue | Eversoul | Prydwen Institute"
    description="List of buildings available in Eversoul."
  />
);
